<template>
  <v-text-field
      :type="item.inputType?item.inputType:'text'"
      v-show="!item.hiddenIfMissingModel || model[item.hiddenIfMissingModel]"
      outlined
      :label="item.label"
      hide-details
      dense
      :loading="loading"
      :name="item.name"
      :clearable="item.clearable?true:false"
      v-model="model[item.modelName]"
      class="font-weight-bold text-subtitle-1"
      :suffix="item.suffix?item.suffix:''"
  >
  </v-text-field>
  <!-- v-model="model[item.joinModelCondition ? model[item.joinedModelName].value+item.modelName : item.modelName]" -->
</template>
<script>
export default {

  props: {
    item: {
      required: true,
      type: Object
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: false,
      type: Boolean
    },
  },
  computed: {
  },
  methods: {
  }
}
</script>
