<template>
  <label>{{item.label}}</label>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
  },
  computed: {
  },
  methods: {
  }
}
</script>
