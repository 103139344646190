<template>
  <v-combobox
    small-chips
    :clearable="item.clearable ? true : false"
    outlined
    dense
    :hidden="
      item.hiddenIfMissing &&
      model[item.hiddenIfMissingModel] === model[item.hiddenIfValue]
    "
    deletable-chips
    :multiple="item.multiple ? true : false"
    hide-details
    class="font-weight-bold text-subtitle-1"
    :items="items"
    :name="item.name"
    :loading="loading"
    @click.native="loadDataFromApi()"
    v-model="model[item.modelName]"
    :label="item.label"
  >
    <template v-slot:selection="{ item, parent, selected }">
      <v-chip color="white" outlined :input-value="selected" small>
        <span class="pr-2">
          {{ item }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
    model: {
      required: true,
      type: [Object, Array],
    },
    loading: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    items: {
      get() {
        return this.item.loadExternaly
          ? this.$store.getters[this.item.dataGetter]
          : this.item.options;
      },
    },
  },
  methods: {
    async loadDataFromApi() {
      if (this.items.length == 0 && this.item.loadExternaly) {
        await this.$store.dispatch(this.item.dataDispatch);
      }
    },
  },
};
</script>
