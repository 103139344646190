<template>
  <div>
<v-expand-transition>
    <filter-toolbar :loading.sync="loading"  :color="color" v-on:applyFilters="applyFilters" v-on:resetFilters="resetFilters">
    </filter-toolbar>
</v-expand-transition>
<v-card   :color="color" dark>
      <v-row align="center" no-gutters :color="color">
          <v-col
            class="px-5 py-2"
            :class="data.class"
            v-for="(data, index) in filters"
            :key="index"
          >
            <component
              v-bind:is="data.fieldType"
              :item="data"
              :model.sync="model"
              :loading.sync="loading"
            ></component>
          </v-col>
        </v-row>
      </v-card>
</div>
</template>
<script>
import components from "@/components/core/form/formElements";
export default {
  components: {
    FilterToolbar: () => import("@/components/core/filters/FilterToolbar"),
    ...components
  },
  props: {
    filters: {
      required: true,
      type: [Object,Array]
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: true,
      type: Boolean
    },
    color: {
      required: false,
      type: String,
      default:'light-blue'
    },
  },
  methods: {
      resetFilters() {
        this.$emit('resetFilters')
      },
      applyFilters() {
        this.$emit('applyFilters')
      },
  },

}
</script>
