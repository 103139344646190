<template>
  <div>
  <v-autocomplete
      :items="items"
      :loading="loading"
      small-chips
      v-show="showCondition()"
      @click.native="loadDataFromApi()"
      v-model="model[item.modelName]"
      :label="item.label"
      :item-text="item.text"
      :item-value="item.value"
      :multiple="item.multiple?true:false"
      dense
      :clearable="item.clearable?true:false"
      outlined
      :return-object="item.returnObject?true:false"
      :name="item.name"
      hide-selected
      hide-details
      class="font-weight-bold text-subtitle-1"
  >
  <template v-slot:selection="{ item, parent, selected }">
    <v-chip color="white" outlined :input-value="selected" small>
      <span class="pr-2">
        {{ item.display_name || item.text }}
      </span>
      <v-icon small @click="parent.selectItem(item)">
        $delete
      </v-icon>
    </v-chip>
  </template>
  </v-autocomplete>
</div>
</template>
<script>
export default {

  props: {
    item: {
      required: true,
      type: Object
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: false,
      type: Boolean
    },
  },
  computed: {
    items: {
      get() {
        return this.item.loadExternaly ? this.$store.getters[this.item.dataGetter] : this.item.options;
      }
    },
  },
  methods: {
     async loadDataFromApi(){
         if(this.items.length==0 && this.item.loadExternaly){
        await this.$store.dispatch(this.item.dataDispatch);
          }
      },
      showCondition(){
        if(this.item.hiddenIfMissing){
          return this.item.hiddenIfValue.includes(this.model[this.item.hiddenIfMissingModel])
        }
        return true
      }
  }
}
</script>
