<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          hide-details
          v-model="model[item.modelName]"
          dense
          :label="item.label"
          readonly
          outlined
          :loading="loading"
          :name="item.name"
          v-show="item.hiddenIfMissing && !item.hiddenIfMissingModel || model[item.hiddenIfMissingModel]"
          :clearable="item.clearable?true:false"
          v-bind="attrs"
          class="font-weight-bold text-subtitle-1"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="model[item.modelName]"
        @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      menu:false
    }
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: false,
      type: Boolean
    },
  },
  computed: {
  },
  methods: {
  }
}
</script>
