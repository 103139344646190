<template>
  <v-radio-group row v-model="model[item.modelName]" hide-details>
    <v-radio
        v-for="(radios,index) in item.options"
        v-bind:key="index"
        :label="radios.text"
        :value="radios.value"
        class="font-weight-bold text-subtitle-1"
    ></v-radio>
  </v-radio-group>
</template>
<script>
export default {

  props: {
    item: {
      required: true,
      type: Object
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: false,
      type: Boolean
    },
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>
.v-input--selection-controls{
  margin-top: 0px !important ;
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}
</style>
