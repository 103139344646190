<template>
  <v-checkbox
      v-show="showCondition()"
      color="white"
      hide-details
      :loading="loading"
      :label="item.label"
      v-model="model[item.modelName]"
      class="font-weight-bold text-subtitle-1 mt-0"
  ></v-checkbox>
</template>
<script>
export default {

  props: {
    item: {
      required: true,
      type: Object
    },
    model: {
      required: true,
      type: [Object,Array]
    },
    loading: {
      required: false,
      type: Boolean
    },
  },
  computed: {
  },
  methods: {
    showCondition(){
      if(this.item.hiddenIfMissing){
        return this.model[this.item.hiddenIfMissingModel]===this.item.hiddenIfValue || this.model[this.item.hiddenIfMissingModel]?.value===this.item.hiddenIfValue
      }
      return true
    }
  }
}
</script>
