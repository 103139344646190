import FormAutocomplete from "./FormAutocomplete";
import FormCombobox from "./FormCombobox";
import FormDate from "./FormDate";
import FormTextField from "./FormTextField";
import FormLabel from "./FormLabel";
import FormRadioGroup from "./FormRadioGroup";
import FormCheckbox from "./FormCheckbox";

export default {
	FormAutocomplete,
	FormCombobox,
	FormDate,
	FormTextField,
	FormLabel,
	FormRadioGroup,
	FormCheckbox
};
